import { ApolloLink, HttpLink } from '@apollo/client/core'
import { EnvironmentVariables } from '@app-types/environment.types'
import { isNil, tap, toPairs } from 'ramda'
import Bugsnag from '@bugsnag/browser'
import { AuthStorageService } from '@app-services/auth-storage/auth-storage.service'
import { setContext } from '@apollo/client/link/context'

/**
 * Constructs a GraphQL API URL for the given schema and hostname. Optionally takes a custom path
 * for the URL (default 'graphql')
 */
export function graphQlApiUrl(env: { schema: string; hostname: string }, path: string = 'graphql'): string {
    return `${env.schema}://${env.hostname}/${path}`
}

/**
 * Creates an Apollo link that performs logging of request IO to the bug tracker,
 * and registers debugging URL search-params to the operation contexts for
 * injection into the request URLs.
 */
export function createApiLogLink(): ApolloLink {
    let nextRequestId = 1
    return new ApolloLink((operation, forward) => {
        const requestId = nextRequestId++
        const { operationName, variables } = operation

        Bugsnag.leaveBreadcrumb(`Fetch #${requestId} | Init`, { requestId, operationName, variables })

        operation.setContext({
            searchParams: {
                requestId,
                operationName,
            },
        })

        return forward(operation).map(
            tap(({ errors, context, data, extensions }) => {
                Bugsnag.leaveBreadcrumb(`Fetch #${requestId} | Result`, { errors, context, data, extensions })
            }),
        )
    })
}

/**
 * Creates an {@link HttpLink Apollo HTTP link} for the given environment.
 */
export function createApiHttpLink(environment: EnvironmentVariables): HttpLink {
    return new HttpLink({
        uri: (operation) => {
            const url = new URL(graphQlApiUrl(environment.api))

            for (const [key, value] of toPairs(operation.getContext().searchParams)) {
                url.searchParams.append(key, value)
            }

            return url.toString()
        },
    })
}

/**
 * Creates an Authentication {@link ApolloLink link} that adds the `Bearer` token sourced from given
 * {@link AuthStateStore auth-state store} as headers to all GraphQL requests.
 */
export function createApiAuthLink(authStorageService: AuthStorageService): ApolloLink {
    return setContext(() => {
        const currentTokens = authStorageService.getAuthState()
        return isNil(currentTokens) ? {} : { headers: { authorization: `Bearer ${currentTokens.accessToken}` } }
    })
}

export interface MenuItemStockInfo {
    menuItemID: string
    menuItemStockID: string | null

    /** Equals `Infinity` for unlimited capacity */
    total: number

    /** Equals `Infinity` for unlimited capacity */
    remaining: number
    capacityLevel: CapacityLevel
}

/**
 * Proxy interface for the union of {@link MenuItemStock the API's MenuItemStock} and
 * {@link StockUpdate the subscription-service's StockUpdate} types, containing the
 * bare minimum info to determine stock conditions.
 */
export interface StockNumbers {
    stockTotal: number
    stockClaimed: number
}

export enum CapacityLevel {
    UNLIMITED = 'unlimited',
    PLENTY = 'plenty',
    LITTLE = 'little',
    EXHAUSTED = 'exhausted',
}

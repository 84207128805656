import { Injectable } from '@angular/core'
import { MeFragment, MenuItemFragment, MenuItemStockFragment, MenuItemStockQueryService } from '@app-graphql/api-schema'
import { StockUpdate, StockUpdatesSubscriptionService } from '@app-graphql/pubsub-schema'
import { formatDate, now } from '@app-lib/date-time.lib'
import { distinctUntilChangedEquals } from '@app-lib/rxjs.lib'
import { AuthService } from '@app-services/auth/auth.service'
import { Nil } from '@app-types/common.types'
import { clamp, isNil } from 'ramda'
import { distinctUntilChanged, filter, map, Observable, of, startWith, Subject, switchMap } from 'rxjs'
import {
    CapacityLevel,
    MenuItemStockInfo,
    StockNumbers,
} from '@app-services/menu-item-stocks/menu-item-stocks.service.types'

@Injectable({
    providedIn: 'root',
})
export class MenuItemStocksService {

    private readonly ABSOLUTE_MIN_THRESHOLD = 5
    private readonly ABSOLUTE_MAX_THRESHOLD = 20
    private readonly FRACTION_TARGET_THRESHOLD = 0.2

    private readonly stockUpdates$ = new Subject<StockUpdate>()

    constructor(
        private readonly stockUpdatesSubscriptionService: StockUpdatesSubscriptionService,
        private readonly menuItemStockQueryService: MenuItemStockQueryService,
        private readonly authService: AuthService,
    ) { }

    public async initialize(): Promise<void> {
        this.authService.getUserObservable().pipe(
            map(({ user }) => user),
            // To subscribe to the stock updated subscription, we need to be sure that we are authorized.
            filter((user): user is MeFragment => ! isNil(user)),
            distinctUntilChanged((prev, curr) => prev?.id === curr.id),
            switchMap((user) => this.stockUpdatesSubscriptionService.subscribe({
                // Todo: we can narrow this scope to location for this context.
                clientID: user.client.registrationShortcode,
            })),
            map((stockResult) => stockResult.data?.stockUpdates),
            filter((x): x is StockUpdate => ! isNil(x)),
        ).subscribe((update) => {
            this.stockUpdates$.next(update)
        })
    }

    /**
     * Returns an observable that emits the stock left for the given menu item and date.
     */
    public watchMenuItemStock(menuItem: MenuItemFragment, date: Date = now()): Observable<MenuItemStockInfo | null> {
        const stock = menuItem.menuItemStocks.find((menuItemStock) => menuItemStock.date === formatDate(date))

        if (isNil(stock)) {
            return of(null)
        }

        return this.fetchMenuItemStock(stock.id).pipe(
            switchMap((stockOrNull) => {
                if (isNil(stockOrNull)) return of(null)

                return this.stockUpdates$.pipe(
                    filter((update) => {
                        return update.menuItemStockID === stock.id
                            && update.menuItemID === menuItem.id
                    }),
                    map((update): MenuItemStockInfo => ({
                        menuItemID: menuItem.id,
                        menuItemStockID: update.menuItemStockID,
                        total: update.stockTotal,
                        remaining: this.stockRemaining(update),
                        capacityLevel: this.getCapacityLevel(update),
                    })),
                    startWith({
                        menuItemID: menuItem.id,
                        menuItemStockID: stockOrNull.id,
                        total: stockOrNull.stockTotal,
                        remaining: this.stockRemaining(stockOrNull),
                        capacityLevel: this.getCapacityLevel(stockOrNull),
                    }),
                    distinctUntilChangedEquals(),
                )
            }),
        )
    }

    /**
     * Determines the appropriate capacity level for the given stock numbers.
     */
    private getCapacityLevel(stockNumbers: StockNumbers): CapacityLevel {
        if (this.stockIsExhausted(stockNumbers)) {
            return CapacityLevel.EXHAUSTED
        }

        if (this.stockIsLow(stockNumbers)) {
            return CapacityLevel.LITTLE
        }

        return CapacityLevel.PLENTY
    }

    /**
     * Decides if the given stock numbers indicate that the corresponding item is out of stock.
     */
    private stockIsExhausted(numbers: StockNumbers): boolean {
        return this.stockRemaining(numbers) <= 0
    }


    private fetchMenuItemStock(stockId: string): Observable<MenuItemStockFragment | Nil> {
        return this.menuItemStockQueryService.fetch({ id: stockId }).pipe(
            map((result) => result.data.menuItemStock),
        )
    }

    /**
     * Calculates the remaining stock for the given stock numbers.
     */
    private stockRemaining(numbers: StockNumbers): number {
        return Math.max(
            0,
            numbers.stockTotal - numbers.stockClaimed,
        )
    }

    /**
     * Decides if the given stock numbers indicate that the corresponding item is low in stock.
     */
    private stockIsLow(numbers: StockNumbers): boolean {
        return this.stockRemaining(numbers) <= this.lowStockThreshold(numbers.stockTotal)
    }

    /**
     * Returns the threshold number of remaining stock for {@link CapacityLevel.LITTLE low capacity} warnings.
     * This number is constrained to an absolute {@link ABSOLUTE_MIN_THRESHOLD minimum} and
     * {@link ABSOLUTE_MAX_THRESHOLD maximum}, but may within these bounds vary based on a
     * {@link FRACTION_TARGET_THRESHOLD fraction} of the total capacity.
     */
    private lowStockThreshold(totalCapacity: number): number {
        return clamp(
            this.ABSOLUTE_MIN_THRESHOLD,
            this.ABSOLUTE_MAX_THRESHOLD,
            Math.floor(this.FRACTION_TARGET_THRESHOLD * totalCapacity),
        )
    }
}

// noinspection JSUnusedGlobalSymbols

import { gql } from 'apollo-angular'
import { Injectable } from '@angular/core'
import * as Apollo from 'apollo-angular'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    MessageID: string
    Semver: string
}

export interface ApiUpdate {
    readonly messageID: Scalars['MessageID']
    readonly version: Scalars['Semver']
}

export interface Mutation {
    /** Submit an API update signal to all subscribers */
    readonly notifyAPIUpdate: ApiUpdate
    /** Submit an order status update to broadcast to all subscribers */
    readonly updateOrderStatus: Scalars['MessageID']
    /** Submit a new stock update to broadcast to subscribers. */
    readonly updateStock: Scalars['MessageID']
}


export interface MutationNotifyApiUpdateArgs {
    version: Scalars['Semver']
}


export interface MutationUpdateOrderStatusArgs {
    clientShortcode: Scalars['String']
    locationShortcode: Scalars['String']
    nextStatus: OrderStatusEnum
    orderID: Scalars['ID']
    prevStatus?: InputMaybe<OrderStatusEnum>
    restaurantID: Scalars['String']
}


export interface MutationUpdateStockArgs {
    clientShortcode: Scalars['String']
    locationShortcode: Scalars['String']
    menuItemID: Scalars['ID']
    menuItemStockID: Scalars['ID']
    restaurantID: Scalars['String']
    stockClaimed: Scalars['Int']
    stockIssued: Scalars['Int']
    stockTotal: Scalars['Int']
}

export enum OrderStatusEnum {
    Cancelled = 'CANCELLED',
    Failed = 'FAILED',
    Open = 'OPEN',
    Paid = 'PAID',
    PickedUp = 'PICKED_UP',
}

export interface OrderStatusUpdate {
    readonly messageID: Scalars['MessageID']
    readonly nextStatus: OrderStatusEnum
    readonly orderID: Scalars['ID']
    readonly prevStatus?: Maybe<OrderStatusEnum>
}

export enum OrderStatusUpdateScopeType {
    Client = 'CLIENT',
    Location = 'LOCATION',
    Order = 'ORDER',
    Restaurant = 'RESTAURANT',
}

export interface Query {
    readonly version: Scalars['Semver']
}

export interface StockUpdate {
    readonly menuItemID: Scalars['ID']
    readonly menuItemStockID: Scalars['ID']
    readonly messageID: Scalars['MessageID']
    readonly stockClaimed: Scalars['Int']
    readonly stockIssued: Scalars['Int']
    readonly stockTotal: Scalars['Int']
}

export interface Subscription {
    /** Subscribe to API update events. Emits whenever a new API version has been deployed. */
    readonly APIUpdates: ApiUpdate
    /**
   * Subscribe to order status updates for a given scope.
   * Provide the scope-type and the main identifier of the scope to subscribe to.
   * For clients and locations this is their shortcode. For restaurants it's their ID.
   */
    readonly orderStatusUpdates: OrderStatusUpdate
    /**
   * Subscribe to stock updates for a given scope.
   * Provide the scope-type and the main identifier of the scope to subscribe to.
   * For clients and locations this is their shortcode. For restaurants, menu-items
   * and stocks, it's their ID.
   */
    readonly stockUpdates: StockUpdate
}


export interface SubscriptionOrderStatusUpdatesArgs {
    clientID: Scalars['String']
    scopeID: Scalars['String']
    scopeType: OrderStatusUpdateScopeType
}


export interface SubscriptionStockUpdatesArgs {
    clientID: Scalars['String']
    scopeID: Scalars['String']
    scopeType: SubscriptionScopeType
}

export enum SubscriptionScopeType {
    Client = 'CLIENT',
    Location = 'LOCATION',
    MenuItem = 'MENU_ITEM',
    MenuItemStock = 'MENU_ITEM_STOCK',
    Restaurant = 'RESTAURANT',
}

export type StockUpdatesSubscriptionVariables = Exact<{
    clientID: Scalars['String']
}>


export type StockUpdatesSubscription = { readonly stockUpdates: { readonly messageID: string; readonly menuItemID: string; readonly menuItemStockID: string; readonly stockTotal: number; readonly stockClaimed: number } }

export const StockUpdatesDocument = gql`
    subscription stockUpdates($clientID: String!) {
  stockUpdates(clientID: $clientID, scopeID: $clientID, scopeType: CLIENT) {
    messageID
    menuItemID
    menuItemStockID
    stockTotal
    stockClaimed
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class StockUpdatesSubscriptionService extends Apollo.Subscription<StockUpdatesSubscription, StockUpdatesSubscriptionVariables> {
    override document = StockUpdatesDocument
    override client = 'pubsub'
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
import { Injectable } from '@angular/core'
import { AuthStorageService } from '@app-services/auth-storage/auth-storage.service'
import { addSeconds, differenceInMilliseconds, isPast } from 'date-fns'
import {
    AuthPayloadFragment,
    LoginWithShortcodeMutationService,
    MeFragment,
    MeQueryService,
    RefreshTokenMutationService,
} from '@app-graphql/api-schema'
import { filter, firstValueFrom, map, Observable, ReplaySubject } from 'rxjs'
import { AuthState } from '@app-services/auth-storage/auth-storage.types'
import { isNil } from 'ramda'
import { Nil } from '@app-types/common.types'
import Bugsnag from '@bugsnag/js'
import { environment } from '@app-environments/environment'

interface UserInfo {
    isLoading: boolean
    user: MeFragment | null
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly userInfo$ = new ReplaySubject<UserInfo>(1)

    /**
     * A margin in seconds for access-token refreshing. A safe margin ensures there cannot be race conditions
     * between the access token being refreshed in time and other requests around the time of access-token expiry.
     */
    private readonly TOKEN_REFRESH_MARGIN_SECONDS = 60
    private tokenRefreshTimeout?: number

    constructor(
        private readonly authStorageService: AuthStorageService,
        private readonly refreshTokenMutationService: RefreshTokenMutationService,
        private readonly meQueryService: MeQueryService,
        private readonly loginWithShortcodeMutationService: LoginWithShortcodeMutationService,
    ) {
    }

    public async initialize(): Promise<void> {
        try {
            this.authStorageService.initialize()
            const authState = this.authStorageService.getAuthState()

            if (! authState) {
                this.userInfo$.next({ isLoading: false, user: null })
                return
            }

            if (isPast(authState.expiresAt)) await this.refreshAccessToken()
            else this.rescheduleTokenRefresh(authState)

            await this.refreshUser()
        } catch (err) {
            console.error('Error trying to initialize auth service', err)
        }
    }

    public async getCurrentUser(): Promise<MeFragment | null> {
        return firstValueFrom(this.userInfo$.pipe(filter((info) => info.isLoading === false), map(({ user }) => user)))
    }

    public getUserObservable(): Observable<UserInfo> {
        return this.userInfo$.asObservable()
    }

    /**
     * Performs the authorization using a shortcode.
     */
    public async authorize(shortcode: string): Promise<boolean> {
        try {
            const result = await firstValueFrom(this.loginWithShortcodeMutationService.mutate({
                input: {
                    shortcode,
                    clientId: environment.api.clientId,
                    clientSecret: environment.api.clientSecret,
                },
            }))

            const authState: Partial<AuthState> = this.convertAuthPayloadToAuthState(result.data?.loginWithShortcode)

            if (! this.authStorageService.isValidAuthState(authState)) {
                throw new Error('Invalid auth state')
            }

            this.authStorageService.submitAuthState(authState)
            await this.refreshUser()
            return true
        } catch (err) {
            console.log('Error trying to authorize', err)
            this.authStorageService.clearAuthState()
            this.userInfo$.next({ isLoading: false, user: null })
            return false
        }

    }

    /**
     * Closed procedure that performs a token refresh operation: Takes the refresh token from
     * the current state, fetches new tokens, stores them locally and pushes them into the
     * observable streams.
     */
    private async refreshAccessToken(): Promise<void> {
        const state = this.authStorageService.getAuthState()

        if (state === null) {
            throw new Error('Invalid attempt to refresh access token.')
        }

        const responseData = await firstValueFrom(this.refreshTokenMutationService
            .mutate({ input: { refresh_token: state.refreshToken } })
            .pipe(map((result) => result.data?.refreshToken)))

        const authState: Partial<AuthState> = {
            accessToken: responseData?.access_token,
            expiresAt: this.expiresInToExpiresAt(responseData?.expires_in),
            refreshToken: responseData?.refresh_token,
            tokenType: responseData?.token_type,
        }

        if (! this.authStorageService.isValidAuthState(authState)) {
            return Bugsnag.notify(`Unexpected token-refresh response: ${JSON.stringify(responseData)}`)
        }


        this.authStorageService.submitAuthState(authState)
        this.rescheduleTokenRefresh(authState)
    }

    /**
     * Derives an 'expires at' date instance from the given 'expires in' amount in seconds.
     */
    private expiresInToExpiresAt(expiresInSeconds: number | Nil): Date | undefined {
        if (isNil(expiresInSeconds)) return undefined
        return addSeconds(new Date(), expiresInSeconds)
    }

    /**
     * Reschedules the next access-token refresh operation as appropriate for the given auth state.
     */
    private rescheduleTokenRefresh(state: AuthState | null): void {
        window.clearTimeout(this.tokenRefreshTimeout)

        if (state === null) return

        this.tokenRefreshTimeout = window.setTimeout(
            () => this.refreshAccessToken(),
            differenceInMilliseconds(state.expiresAt, new Date()) - this.TOKEN_REFRESH_MARGIN_SECONDS * 1000,
        )
    }

    /**
     * Re-fetches the user data and pushes the new data into the user observable streams.
     */
    public async refreshUser(): Promise<void> {
        this.userInfo$.next({ isLoading: true, user: null })
        const user = await this.fetchUser()
        this.userInfo$.next({ isLoading: false, user })
    }

    private async fetchUser(): Promise<MeFragment | null> {
        try {
            const result = await firstValueFrom(this.meQueryService.fetch(undefined, { fetchPolicy: 'network-only' }))
            return result.data?.me ?? null
        } catch (err) {
            console.error('Error fetching user', err)
            return null
        }
    }

    private convertAuthPayloadToAuthState(authPayload: AuthPayloadFragment | undefined): Partial<AuthState> {
        return {
            accessToken: authPayload?.access_token || undefined,
            expiresAt: this.expiresInToExpiresAt(authPayload?.expires_in),
            refreshToken: authPayload?.refresh_token || undefined,
            tokenType: authPayload?.token_type || undefined,
        }
    }
}

// noinspection JSUnusedGlobalSymbols

import { gql } from 'apollo-angular'
import { Injectable } from '@angular/core'
import * as Apollo from 'apollo-angular'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    /** The `Cent` scalar type represents price in cents */
    Cent: number
    Date: string
    DateTime: string
    /** Represents a Pubsub message ID */
    MessageID: string
    /** Represents a Semver version number such as "1.2.3" */
    Semver: string
    /** Represents a Semver version range such as " >= 1.2.3 || 3.x" */
    SemverRange: string
}

export interface Addition {
    readonly id: Scalars['ID']
    readonly isSelected: Scalars['Boolean']
    readonly name: Scalars['String']
    readonly priceInCents: Scalars['Cent']
}

export interface AdditionBelongsTo {
    readonly connect: Scalars['ID']
}

export interface Allergen {
    readonly icon?: Maybe<Scalars['String']>
    readonly id: Scalars['ID']
    readonly name: Scalars['String']
}


export interface AllergenIconArgs {
    variant?: InputMaybe<AllergenIconVariant>
}

/** Allowed resizes for the `variant` argument on the query `icon`. */
export enum AllergenIconVariant {
    Original = 'ORIGINAL',
}

export interface AllergensBelongsTo {
    readonly connect: Scalars['ID']
}

export interface AmbianceImages {
    readonly original: Scalars['String']
    readonly rectangle_615: Scalars['String']
}

export interface Answer {
    readonly answer?: Maybe<Scalars['String']>
    readonly id: Scalars['ID']
    readonly question: Question
    readonly questionOption?: Maybe<QuestionOption>
    readonly user: User
}

export interface ApiVersion {
    readonly createdAt: Scalars['DateTime']
    readonly id: Scalars['ID']
    readonly updatedAt: Scalars['DateTime']
    readonly version: Scalars['String']
}

export interface AppCompatibilityStatus {
    /** True if the API accepts the given app version. */
    readonly apiSupportsApp: Scalars['Boolean']
    /** The current Semver version of the API. */
    readonly apiVersion: Scalars['Semver']
    /** True if the app accepts the current API version. */
    readonly appSupportsApi: Scalars['Boolean']
    /** The given Semver version of the app; is in the response for reference and debugging. */
    readonly appVersion: Scalars['Semver']
    /** True if both the app and API are happy with each other's current version. */
    readonly compatible: Scalars['Boolean']
}

export interface AuthPayload {
    readonly access_token?: Maybe<Scalars['String']>
    readonly expires_in?: Maybe<Scalars['Int']>
    readonly refresh_token?: Maybe<Scalars['String']>
    readonly token_type?: Maybe<Scalars['String']>
    readonly user?: Maybe<User>
}

export interface ChangeOfficeDaysInput {
    readonly days: ReadonlyArray<InputMaybe<WeekDayEnum>>
}

export interface Client {
    readonly domains: ReadonlyArray<Domain>
    readonly emailQuestionsTo: Scalars['String']
    readonly id: Scalars['ID']
    readonly logo: Scalars['String']
    readonly name: Scalars['String']
    readonly newsPages: ReadonlyArray<NewsPage>
    readonly registrationShortcode: Scalars['String']
    readonly themes: ClientThemes
}

export interface ClientTheme {
    readonly colorBackgroundFirstStop: Scalars['String']
    readonly colorBackgroundOverlay: Scalars['String']
    readonly colorBackgroundOverlayContrast: Scalars['String']
    readonly colorBackgroundSecondStop: Scalars['String']
    readonly colorError: Scalars['String']
    readonly colorErrorContrast: Scalars['String']
    readonly colorPrimary: Scalars['String']
    readonly colorPrimaryContrast: Scalars['String']
    readonly colorSecondary: Scalars['String']
    readonly colorSecondaryContrast: Scalars['String']
    readonly colorSuccess: Scalars['String']
    readonly colorSuccessContrast: Scalars['String']
    readonly colorTertiary: Scalars['String']
    readonly colorTertiaryContrast: Scalars['String']
    readonly colorTextDark: Scalars['String']
    readonly colorTextLight: Scalars['String']
    readonly colorWarning: Scalars['String']
    readonly colorWarningContrast: Scalars['String']
}

export interface ClientThemes {
    readonly app: ClientTheme
    readonly web: ClientTheme
}

export interface CreateAnswerInput {
    readonly answer?: InputMaybe<Scalars['String']>
    readonly question: QuestionBelongsTo
    readonly questionOption?: InputMaybe<QuestionOptionBelongsTo>
}

export interface CreateGroupedOrderDataInput {
    readonly comments?: InputMaybe<Scalars['String']>
    readonly orderLines: ReadonlyArray<CreateOrderLineInput>
    readonly pickUpInput: CreateOrderPickUpInput
}

export interface CreateGroupedOrderInput {
    readonly orders: ReadonlyArray<CreateGroupedOrderDataInput>
}

export interface CreateGroupedPaymentErrorResponse {
    readonly code: Scalars['Int']
    readonly message: Scalars['String']
}

export interface CreateGroupedPaymentInput {
    readonly method: PaymentMethodEnum
    readonly orders: ReadonlyArray<OrderBelongsTo>
    readonly payload: Scalars['String']
}

export interface CreateGroupedPaymentResponse {
    readonly paymentId: Scalars['ID']
    readonly pspRedirectUrl?: Maybe<Scalars['String']>
}

export type CreateGroupedPaymentUnionResponse = CreateGroupedPaymentErrorResponse | CreateGroupedPaymentResponse

export interface CreateOrderDeliveryInput {
    readonly cityName: Scalars['String']
    readonly postalCode: Scalars['String']
    readonly restaurant: RestaurantBelongsTo
    readonly streetName: Scalars['String']
    readonly streetNumber: Scalars['String']
    readonly transferDate: Scalars['Date']
}

export interface CreateOrderLineInput {
    readonly additions?: InputMaybe<ReadonlyArray<AdditionBelongsTo>>
    readonly dietaryRestrictions?: InputMaybe<Scalars['String']>
    readonly menuItem: MenuItemBelongsTo
    readonly productVersion: ProductVersionBelongsTo
    readonly vatTariff: VatTariffBelongsTo
}

export interface CreateOrderPickUpInput {
    readonly deliveryInput?: InputMaybe<CreateOrderDeliveryInput>
    readonly timeslotInput?: InputMaybe<CreateOrderTimeslotInput>
    readonly transferDateInput?: InputMaybe<CreateOrderTransferDateInput>
}

export interface CreateOrderTimeslotInput {
    readonly timeslot: TimeslotBelongsTo
}

export interface CreateOrderTransferDateInput {
    readonly restaurant: RestaurantBelongsTo
    readonly transferDate: Scalars['Date']
}

export interface CreatePaymentInput {
    readonly method: PaymentMethodEnum
    readonly order: OrderBelongsTo
    readonly payload: Scalars['String']
}

export interface CreatePaymentResponse {
    readonly paymentId: Scalars['ID']
    readonly pspRedirectUrl?: Maybe<Scalars['String']>
}

export enum CurrencyEnum {
    /** Euro */
    Euro = 'EURO',
    /** Great britain pound */
    GreatBritainPound = 'GREAT_BRITAIN_POUND',
    /** United states dollar */
    UnitedStatesDollar = 'UNITED_STATES_DOLLAR',
}

export interface DishBackgroundImages {
    readonly ORIGINAL: Scalars['String']
    readonly SQUARE_150: Scalars['String']
    readonly SQUARE_300: Scalars['String']
    readonly SQUARE_600: Scalars['String']
}

export interface Domain {
    readonly client: Client
    readonly id: Scalars['ID']
    readonly name: Scalars['String']
}

export interface ForgotPasswordInput {
    readonly callbackUrl: ResetPasswordUrlInput
    readonly email: Scalars['String']
}

export interface ForgotPasswordResponse {
    readonly message?: Maybe<Scalars['String']>
    readonly status: Scalars['String']
}

export interface IdealIssuer {
    readonly issuerId: Scalars['String']
    readonly name: IdealIssuerEnum
}

export enum IdealIssuerEnum {
    /** Abn amro */
    AbnAmro = 'ABN_AMRO',
    /** Asn bank */
    AsnBank = 'ASN_BANK',
    /** Bunq */
    Bunq = 'BUNQ',
    /** Handelsbanken */
    Handelsbanken = 'HANDELSBANKEN',
    /** Ing */
    Ing = 'ING',
    /** Knab */
    Knab = 'KNAB',
    /** Rabobank */
    Rabobank = 'RABOBANK',
    /** Regiobank */
    Regiobank = 'REGIOBANK',
    /** Sns */
    Sns = 'SNS',
    /** Triodos bank */
    TriodosBank = 'TRIODOS_BANK',
    /** Van lanschot */
    VanLanschot = 'VAN_LANSCHOT',
}

export interface Location {
    readonly cityName: Scalars['String']
    readonly costCenter?: Maybe<Scalars['String']>
    readonly id: Scalars['ID']
    readonly name: Scalars['String']
    readonly postalCode: Scalars['String']
    readonly restaurants: ReadonlyArray<Restaurant>
    readonly shortcode?: Maybe<Scalars['String']>
    readonly streetName: Scalars['String']
    readonly streetNumber: Scalars['String']
}

export interface LocationBelongsTo {
    readonly connect: Scalars['ID']
}

export interface LocationsInput {
    readonly first: Scalars['Int']
    readonly page?: InputMaybe<Scalars['Int']>
}

export interface LocationsPaginator {
    readonly data: ReadonlyArray<Location>
    readonly paginatorInfo: PaginatorInfo
}

export interface LoginInput {
    readonly clientId: Scalars['ID']
    readonly clientSecret: Scalars['String']
    readonly password: Scalars['String']
    readonly username: Scalars['String']
}

export interface LoginWithShortcodeInput {
    readonly clientId: Scalars['ID']
    readonly clientSecret: Scalars['String']
    readonly shortcode: Scalars['String']
}

export interface LogoutResponse {
    readonly message?: Maybe<Scalars['String']>
    readonly status: Scalars['String']
}

export interface MenuFeedDay {
    readonly conflicts: ReadonlyArray<Scalars['ID']>
    readonly date: Scalars['Date']
    readonly restaurants: ReadonlyArray<MenuFeedDayRestaurant>
}

export interface MenuFeedDayRestaurant {
    readonly menuItems: ReadonlyArray<MenuItem>
    readonly restaurant: Restaurant
}

export interface MenuFeedFilterInput {
    readonly locations?: InputMaybe<ReadonlyArray<Scalars['ID']>>
    readonly productCategories?: InputMaybe<ReadonlyArray<Scalars['ID']>>
    readonly productTypes?: InputMaybe<ReadonlyArray<ProductTypeEnum>>
    readonly restaurants?: InputMaybe<ReadonlyArray<Scalars['ID']>>
}

export interface MenuFeedInput {
    readonly date: Scalars['Date']
    readonly filter?: InputMaybe<MenuFeedFilterInput>
}

export interface MenuItem {
    readonly defaultStock?: Maybe<Scalars['Int']>
    readonly id: Scalars['ID']
    readonly menuItemStocks: ReadonlyArray<MenuItemStock>
    readonly position?: Maybe<Scalars['Int']>
    readonly product: Product
    readonly recurringDays: ReadonlyArray<Scalars['Int']>
    readonly recurringEnd?: Maybe<Scalars['Date']>
    readonly recurringStart: Scalars['Date']
    readonly restaurant: Restaurant
    readonly sellingPriceInCents: Scalars['Cent']
}

export interface MenuItemBelongsTo {
    readonly connect: Scalars['ID']
}

export interface MenuItemStock {
    readonly date: Scalars['Date']
    readonly id: Scalars['ID']
    readonly menuItem: MenuItem
    readonly stockClaimed: Scalars['Int']
    readonly stockIssued: Scalars['Int']
    readonly stockTotal: Scalars['Int']
}

export interface MenuItemsInput {
    readonly date: Scalars['Date']
    readonly productType?: InputMaybe<ProductTypeEnum>
    readonly restaurant: RestaurantBelongsTo
}

export interface Mutation {
    readonly confirmTwoFactorCode: RecoveryCode
    readonly createAnswer: Answer
    readonly createGroupedOrder: ReadonlyArray<Order>
    readonly createGroupedPayment: CreateGroupedPaymentResponse
    readonly createGroupedPaymentWithHandledError: CreateGroupedPaymentUnionResponse
    readonly deleteUser: Scalars['Boolean']
    readonly disableTwoFactor: User
    readonly enableTwoFactor: TwoFactorSecret
    readonly forgotPassword: Scalars['String']
    readonly login: AuthPayload
    readonly loginWithShortcode: AuthPayload
    readonly logout: LogoutResponse
    readonly pickUpOrder: Order
    readonly refreshToken: RefreshTokenPayload
    readonly register: RegisterResponse
    readonly shortcodeRegister: RegisterResponse
    readonly updateForgottenPassword: ForgotPasswordResponse
    readonly updateMenuItemStock: StockUpdateResult
    readonly updateOfficeDays: ReadonlyArray<OfficeDay>
    readonly updatePassword: UpdatePasswordResponse
    readonly updateUser: User
    readonly updateUserAllergens: User
    readonly verifyEmail: AuthPayload
    readonly verifyPayment: Payment
}


export interface MutationConfirmTwoFactorCodeArgs {
    code: Scalars['String']
}


export interface MutationCreateAnswerArgs {
    input: CreateAnswerInput
}


export interface MutationCreateGroupedOrderArgs {
    input: CreateGroupedOrderInput
}


export interface MutationCreateGroupedPaymentArgs {
    input: CreateGroupedPaymentInput
}


export interface MutationCreateGroupedPaymentWithHandledErrorArgs {
    input: CreateGroupedPaymentInput
}


export interface MutationDeleteUserArgs {
    id: Scalars['ID']
}


export interface MutationForgotPasswordArgs {
    email: Scalars['String']
}


export interface MutationLoginArgs {
    input?: InputMaybe<LoginInput>
}


export interface MutationLoginWithShortcodeArgs {
    input: LoginWithShortcodeInput
}


export interface MutationPickUpOrderArgs {
    input: PickUpOrderInput
}


export interface MutationRefreshTokenArgs {
    input?: InputMaybe<RefreshTokenInput>
}


export interface MutationRegisterArgs {
    input?: InputMaybe<RegisterInput>
}


export interface MutationShortcodeRegisterArgs {
    input?: InputMaybe<ShortcodeRegisterInput>
}


export interface MutationUpdateForgottenPasswordArgs {
    input?: InputMaybe<NewPasswordWithCodeInput>
}


export interface MutationUpdateMenuItemStockArgs {
    id: Scalars['ID']
    mutationQuantity: Scalars['Int']
    mutationType: StockMutationTypeEnum
}


export interface MutationUpdateOfficeDaysArgs {
    input: ChangeOfficeDaysInput
}


export interface MutationUpdatePasswordArgs {
    input: UpdatePassword
}


export interface MutationUpdateUserArgs {
    input: UpdateUserInput
}


export interface MutationUpdateUserAllergensArgs {
    input: UpdateUserAllergensInput
}


export interface MutationVerifyEmailArgs {
    input: VerifyEmailInput
}


export interface MutationVerifyPaymentArgs {
    id: Scalars['ID']
}

export interface NewPasswordWithCodeInput {
    readonly email: Scalars['String']
    readonly password: Scalars['String']
    readonly password_confirmation: Scalars['String']
    readonly token: Scalars['String']
}

export interface NewsArticle {
    readonly body?: Maybe<Scalars['String']>
    readonly id: Scalars['ID']
    readonly image?: Maybe<Scalars['String']>
    readonly position?: Maybe<Scalars['String']>
    readonly title?: Maybe<Scalars['String']>
}


export interface NewsArticleImageArgs {
    variant?: InputMaybe<NewsArticleImageVariant>
}

/** Allowed resizes for the `variant` argument on the query `image`. */
export enum NewsArticleImageVariant {
    Original = 'ORIGINAL',
}

export interface NewsPage {
    readonly articles: ReadonlyArray<NewsArticle>
    readonly clients: ReadonlyArray<Client>
    readonly heroImage?: Maybe<Scalars['String']>
    readonly id: Scalars['ID']
    readonly intro: Scalars['String']
    readonly published_at?: Maybe<Scalars['Date']>
    readonly tagline?: Maybe<Scalars['String']>
    readonly title: Scalars['String']
}

export interface NewsPagesInput {
    readonly date?: InputMaybe<Scalars['Date']>
    readonly first: Scalars['Int']
    readonly page?: InputMaybe<Scalars['Int']>
}

export interface NewsPagesPaginator {
    readonly data: ReadonlyArray<NewsPage>
    readonly paginatorInfo: PaginatorInfo
}

export interface Nutrient {
    readonly NutrientType?: Maybe<NutrientType>
    readonly ProductVersion?: Maybe<ProductVersion>
    readonly perHundredGram?: Maybe<Scalars['Float']>
    readonly perPortion?: Maybe<Scalars['Float']>
    readonly referenceIntake?: Maybe<Scalars['Float']>
}

export interface NutrientType {
    readonly id: Scalars['ID']
    readonly name: Scalars['String']
    readonly unit: NutrientTypeUnitEnum
}

export enum NutrientTypeUnitEnum {
    /** G */
    G = 'G',
    /** Gram */
    Gram = 'GRAM',
    /** Kcal */
    Kcal = 'KCAL',
}

export interface OfficeDay {
    readonly closingTime?: Maybe<Scalars['String']>
    readonly dayOfTheWeek: WeekDayEnum
    readonly id: Scalars['ID']
    readonly openingTime?: Maybe<Scalars['String']>
    readonly user: User
}

export interface Order {
    readonly cityName?: Maybe<Scalars['String']>
    readonly comments?: Maybe<Scalars['String']>
    readonly createdAt: Scalars['DateTime']
    readonly expiredAt?: Maybe<Scalars['DateTime']>
    readonly id: Scalars['ID']
    readonly isOrderOnAccount: Scalars['Boolean']
    readonly orderLines: ReadonlyArray<OrderLine>
    readonly paidAt?: Maybe<Scalars['DateTime']>
    readonly payment?: Maybe<Payment>
    readonly pickedUpAt?: Maybe<Scalars['DateTime']>
    readonly pickedUpOrderLineIds?: Maybe<ReadonlyArray<Scalars['ID']>>
    readonly postalCode?: Maybe<Scalars['String']>
    readonly reservedAt: Scalars['DateTime']
    readonly restaurant: Restaurant
    readonly status: OrderStatusEnum
    readonly streetName?: Maybe<Scalars['String']>
    readonly streetNumber?: Maybe<Scalars['String']>
    readonly totalPrice: Scalars['Cent']
    readonly transferableFrom: Scalars['DateTime']
    readonly transferableUntil: Scalars['DateTime']
    readonly updatedAt: Scalars['DateTime']
    readonly user: User
}

export interface OrderBelongsTo {
    readonly connect: Scalars['ID']
}

/** Allows ordering a list of records. */
export interface OrderByClause {
    /** The column that is used for ordering. */
    readonly column: Scalars['String']
    /** The direction that is used for ordering. */
    readonly order: SortOrder
}

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
    /** Amount of items. */
    Count = 'COUNT',
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
    /** Average. */
    Avg = 'AVG',
    /** Amount of items. */
    Count = 'COUNT',
    /** Maximum. */
    Max = 'MAX',
    /** Minimum. */
    Min = 'MIN',
    /** Sum. */
    Sum = 'SUM',
}

export interface OrderFeedDay {
    readonly date: Scalars['Date']
    readonly orders: ReadonlyArray<Order>
}

export interface OrderFeedFilterInput {
    readonly restaurant: Scalars['ID']
}

export interface OrderFeedInput {
    readonly date: Scalars['Date']
    readonly filter?: InputMaybe<OrderFeedFilterInput>
}

export interface OrderHistoryFilterConsumptionDateRangeInput {
    /** Format: yyyy-MM-dd */
    readonly endDate?: InputMaybe<Scalars['Date']>
    /** Format: yyyy-MM-dd */
    readonly startDate?: InputMaybe<Scalars['Date']>
}

export interface OrderHistoryFilterInput {
    readonly consumptionDateRange?: InputMaybe<OrderHistoryFilterConsumptionDateRangeInput>
    /** Defaults to false */
    readonly excludePickedUp?: InputMaybe<Scalars['Boolean']>
    readonly restaurant?: InputMaybe<RestaurantBelongsTo>
}

export interface OrderHistoryInput {
    readonly filter?: InputMaybe<OrderHistoryFilterInput>
    readonly pagination: OrderHistoryPaginationInput
    readonly sorting: OrderHistorySortingEnum
}

export interface OrderHistoryPaginationInput {
    readonly first: Scalars['Int']
    readonly page?: InputMaybe<Scalars['Int']>
}

export interface OrderHistoryPaginator {
    readonly data: ReadonlyArray<Order>
    readonly paginatorInfo: PaginatorInfo
}

export enum OrderHistorySortingEnum {
    /** Consumption date desc */
    ConsumptionDateDesc = 'CONSUMPTION_DATE_DESC',
    /** Paid at desc */
    PaidAtDesc = 'PAID_AT_DESC',
}

export interface OrderLine {
    readonly additions: ReadonlyArray<Addition>
    readonly dietaryRestrictions?: Maybe<Scalars['String']>
    readonly id: Scalars['ID']
    readonly productVersion: ProductVersion
    readonly totalPrice: Scalars['Cent']
    readonly vatTariff: VatTariff
}

export enum OrderStatusEnum {
    /** Failed */
    Failed = 'FAILED',
    /** Open */
    Open = 'OPEN',
    /** Paid */
    Paid = 'PAID',
}

export interface OrdersPaginator {
    readonly data: ReadonlyArray<Order>
    readonly paginatorInfo: PaginatorInfo
}

/** Information about pagination using a Relay style cursor connection. */
export interface PageInfo {
    /** Number of nodes in the current page. */
    readonly count: Scalars['Int']
    /** Index of the current page. */
    readonly currentPage: Scalars['Int']
    /** The cursor to continue paginating forwards. */
    readonly endCursor?: Maybe<Scalars['String']>
    /** When paginating forwards, are there more items? */
    readonly hasNextPage: Scalars['Boolean']
    /** When paginating backwards, are there more items? */
    readonly hasPreviousPage: Scalars['Boolean']
    /** Index of the last available page. */
    readonly lastPage: Scalars['Int']
    /** The cursor to continue paginating backwards. */
    readonly startCursor?: Maybe<Scalars['String']>
    /** Total number of nodes in the paginated connection. */
    readonly total: Scalars['Int']
}

/** Information about pagination using a fully featured paginator. */
export interface PaginatorInfo {
    /** Number of items in the current page. */
    readonly count: Scalars['Int']
    /** Index of the current page. */
    readonly currentPage: Scalars['Int']
    /** Index of the first item in the current page. */
    readonly firstItem?: Maybe<Scalars['Int']>
    /** Are there more pages after this one? */
    readonly hasMorePages: Scalars['Boolean']
    /** Index of the last item in the current page. */
    readonly lastItem?: Maybe<Scalars['Int']>
    /** Index of the last available page. */
    readonly lastPage: Scalars['Int']
    /** Number of items per page. */
    readonly perPage: Scalars['Int']
    /** Number of total available items. */
    readonly total: Scalars['Int']
}

export interface Payment {
    readonly amountInCents: Scalars['Cent']
    readonly checkoutUrl?: Maybe<Scalars['String']>
    readonly currency: CurrencyEnum
    readonly feeAmountInCents?: Maybe<Scalars['Cent']>
    readonly id: Scalars['ID']
    readonly method: PaymentMethodEnum
    readonly molliePaymentId?: Maybe<Scalars['String']>
    readonly orders: ReadonlyArray<Order>
    readonly refundedAmountInCents: Scalars['Cent']
    readonly status: PaymentStatusEnum
}

export interface PaymentMethod {
    readonly key: PaymentMethodEnum
    readonly value: Scalars['String']
}

export enum PaymentMethodEnum {
    /** Bill to client */
    BillToClient = 'BILL_TO_CLIENT',
    /** Credit card */
    CreditCard = 'CREDIT_CARD',
    /** Ideal */
    Ideal = 'IDEAL',
}

export interface PaymentProfile {
    readonly mollieProfileId: Scalars['String']
    readonly testMode: Scalars['Boolean']
}

export enum PaymentStatusEnum {
    /** Authorized */
    Authorized = 'AUTHORIZED',
    /** Canceled */
    Canceled = 'CANCELED',
    /** Expired */
    Expired = 'EXPIRED',
    /** Failed */
    Failed = 'FAILED',
    /** Open */
    Open = 'OPEN',
    /** Paid */
    Paid = 'PAID',
    /** Pending */
    Pending = 'PENDING',
}

export interface PaymentsInput {
    readonly first: Scalars['Int']
    readonly page?: InputMaybe<Scalars['Int']>
}

export interface PaymentsPaginator {
    readonly data: ReadonlyArray<Payment>
    readonly paginatorInfo: PaginatorInfo
}

export interface PickUpOrderInput {
    readonly orderId: Scalars['ID']
    /** Empty, partial and complete list of IDs allowed */
    readonly orderLineIds: ReadonlyArray<Scalars['ID']>
}

export interface Product {
    readonly category: ProductCategory
    readonly currentVersion: ProductVersion
    readonly id: Scalars['ID']
    readonly name: Scalars['String']
    readonly tagline?: Maybe<Scalars['String']>
    readonly tags: ReadonlyArray<Tag>
    readonly type: ProductTypeEnum
    readonly versions: ReadonlyArray<ProductVersion>
}

export enum ProductCategoriesScopeTypeEnum {
    /** Client */
    Client = 'CLIENT',
    /** Location */
    Location = 'LOCATION',
    /** Restaurant */
    Restaurant = 'RESTAURANT',
}

export interface ProductCategory {
    readonly id: Scalars['ID']
    readonly name: Scalars['String']
}

export interface ProductCategoryBelongsTo {
    readonly connect: Scalars['ID']
}

export enum ProductTypeEnum {
    /** Drink */
    Drink = 'DRINK',
    /** Meal */
    Meal = 'MEAL',
    /** Side dish */
    SideDish = 'SIDE_DISH',
}

export interface ProductVersion {
    readonly additions: ReadonlyArray<Addition>
    readonly allergens: ReadonlyArray<Allergen>
    readonly description?: Maybe<Scalars['String']>
    readonly endsAt?: Maybe<Scalars['Date']>
    readonly id: Scalars['ID']
    readonly image?: Maybe<ProductVersionImage>
    readonly nutrients: ReadonlyArray<Nutrient>
    readonly product: Product
    readonly startsAt: Scalars['Date']
    readonly vat: Vat
}

export interface ProductVersionBelongsTo {
    readonly connect: Scalars['ID']
}

export interface ProductVersionImage {
    /** @deprecated Will be removed in V3 */
    readonly image?: Maybe<Scalars['String']>
    readonly variants: ProductVersionImages
}


export interface ProductVersionImageImageArgs {
    variant?: InputMaybe<ProductVersionImageImageVariant>
}

/** Allowed resizes for the `variant` argument on the query `image`. */
export enum ProductVersionImageImageVariant {
    Original = 'ORIGINAL',
    Square_150 = 'SQUARE_150',
    Square_300 = 'SQUARE_300',
    Square_600 = 'SQUARE_600',
}

export enum ProductVersionImageVariantEnum {
    /** Original */
    Original = 'ORIGINAL',
    /** Square 150 */
    Square_150 = 'SQUARE_150',
    /** Square 300 */
    Square_300 = 'SQUARE_300',
    /** Square 600 */
    Square_600 = 'SQUARE_600',
}

export interface ProductVersionImages {
    readonly ORIGINAL: Scalars['String']
    readonly SQUARE_150: Scalars['String']
    readonly SQUARE_300: Scalars['String']
    readonly SQUARE_600: Scalars['String']
}

export interface ProductVersionImagesInput {
    readonly productVersionId: Scalars['ID']
}

export interface Query {
    readonly allergen?: Maybe<Allergen>
    readonly allergens: ReadonlyArray<Allergen>
    readonly apiVersion: ApiVersion
    readonly appCompatibility: AppCompatibilityStatus
    readonly client?: Maybe<Client>
    readonly clientSuggestion?: Maybe<Client>
    readonly clients: ReadonlyArray<Client>
    readonly idealIssuers: ReadonlyArray<IdealIssuer>
    readonly latestNewsPage: NewsPage
    readonly location: Location
    readonly locations: LocationsPaginator
    readonly me: User
    readonly menuFeed: MenuFeedDay
    readonly menuItem: MenuItem
    readonly menuItemStock?: Maybe<MenuItemStock>
    readonly menuItems: ReadonlyArray<MenuItem>
    readonly newsPage: NewsPage
    readonly newsPages?: Maybe<NewsPagesPaginator>
    readonly nutrientType?: Maybe<NutrientType>
    readonly nutrientTypeUnits: ReadonlyArray<NutrientTypeUnitEnum>
    readonly nutrientTypes: ReadonlyArray<NutrientType>
    readonly order: Order
    /**
   * An order overview created for kitchen-screens
   * Input requires a date and restaurant id
   */
    readonly orderFeed: OrderFeedDay
    readonly orderHistory: OrdersPaginator
    readonly payment: Payment
    readonly paymentMethods: ReadonlyArray<PaymentMethod>
    readonly paymentProfile: PaymentProfile
    readonly payments: PaymentsPaginator
    readonly productCategories: ReadonlyArray<ProductCategory>
    readonly productVersionImages: ProductVersionImages
    readonly question: Question
    readonly questions: ReadonlyArray<Question>
    readonly restaurant: Restaurant
    readonly restaurants: RestaurantsPaginator
    readonly timeslots: ReadonlyArray<Timeslot>
    readonly userStatuses: ReadonlyArray<UserStatus>
    readonly weekDayEnums: ReadonlyArray<WeekDayEnum>
}


export interface QueryAllergenArgs {
    id?: InputMaybe<Scalars['ID']>
}


export interface QueryAppCompatibilityArgs {
    appVersion: Scalars['Semver']
    supportedApiRange: Scalars['SemverRange']
}


export interface QueryClientArgs {
    id?: InputMaybe<Scalars['ID']>
}


export interface QueryClientSuggestionArgs {
    email?: InputMaybe<Scalars['String']>
}


export interface QueryLocationArgs {
    id?: InputMaybe<Scalars['ID']>
    shortcode?: InputMaybe<Scalars['String']>
}


export interface QueryLocationsArgs {
    input: LocationsInput
}


export interface QueryMenuFeedArgs {
    input?: InputMaybe<MenuFeedInput>
}


export interface QueryMenuItemArgs {
    id: Scalars['ID']
}


export interface QueryMenuItemStockArgs {
    id: Scalars['ID']
}


export interface QueryMenuItemsArgs {
    input: MenuItemsInput
}


export interface QueryNewsPageArgs {
    id: Scalars['ID']
}


export interface QueryNewsPagesArgs {
    input: NewsPagesInput
}


export interface QueryNutrientTypeArgs {
    id: Scalars['ID']
}


export interface QueryOrderArgs {
    id: Scalars['ID']
}


export interface QueryOrderFeedArgs {
    input?: InputMaybe<OrderFeedInput>
}


export interface QueryOrderHistoryArgs {
    input: OrderHistoryInput
}


export interface QueryPaymentArgs {
    id: Scalars['ID']
}


export interface QueryPaymentsArgs {
    input: PaymentsInput
}


export interface QueryProductCategoriesArgs {
    scopeID: Scalars['String']
    scopeType: ProductCategoriesScopeTypeEnum
}


export interface QueryProductVersionImagesArgs {
    input: ProductVersionImagesInput
}


export interface QueryQuestionArgs {
    id: Scalars['ID']
}


export interface QueryQuestionsArgs {
    input: QuestionsInput
}


export interface QueryRestaurantArgs {
    id: Scalars['ID']
    options?: InputMaybe<RestaurantQueryOptions>
}


export interface QueryRestaurantsArgs {
    input: RestaurantsInput
}


export interface QueryTimeslotsArgs {
    input: TimeslotsInput
}

export interface Question {
    readonly client?: Maybe<Client>
    readonly date: Scalars['Date']
    readonly feedback?: Maybe<Scalars['String']>
    readonly id: Scalars['ID']
    readonly placeholder?: Maybe<Scalars['String']>
    readonly question: Scalars['String']
    readonly questionOptions: ReadonlyArray<QuestionOption>
    readonly type: QuestionTypeEnum
}

export interface QuestionBelongsTo {
    readonly connect: Scalars['ID']
}

export interface QuestionOption {
    readonly id: Scalars['ID']
    readonly option: Scalars['String']
    readonly question: Question
}

export interface QuestionOptionBelongsTo {
    readonly connect: Scalars['ID']
}

export enum QuestionTypeEnum {
    /** Multiple choice */
    MultipleChoice = 'MULTIPLE_CHOICE',
    /** Open ended */
    OpenEnded = 'OPEN_ENDED',
    /** Smiley */
    Smiley = 'SMILEY',
    /** Star */
    Star = 'STAR',
}

export interface QuestionsInput {
    readonly date: Scalars['Date']
}

export interface RecoveryCode {
    readonly code: Scalars['String']
}

export interface RefreshTokenInput {
    readonly refresh_token?: InputMaybe<Scalars['String']>
}

export interface RefreshTokenPayload {
    readonly access_token: Scalars['String']
    readonly expires_in: Scalars['Int']
    readonly refresh_token: Scalars['String']
    readonly token_type: Scalars['String']
}

export interface RegisterInput {
    readonly email: Scalars['String']
    readonly firstName: Scalars['String']
    readonly lastName: Scalars['String']
    readonly password: Scalars['String']
    readonly password_confirmation: Scalars['String']
}

export interface RegisterResponse {
    readonly status: RegisterStatuses
    readonly tokens?: Maybe<AuthPayload>
}

export enum RegisterStatuses {
    MustVerifyEmail = 'MUST_VERIFY_EMAIL',
    Success = 'SUCCESS',
}

/**
 * The url used to reset the password.
 * Use the `__EMAIL__` and `__TOKEN__` placeholders to inject the reset password email and token.
 *
 * e.g; `https://my-front-end.com?reset-password?email=__EMAIL__&token=__TOKEN__`
 */
export interface ResetPasswordUrlInput {
    readonly url: Scalars['String']
}

export interface Restaurant {
    readonly allowPickUpAtTheOffice: Scalars['Boolean']
    readonly ambianceImage: AmbianceImages
    readonly createdAt: Scalars['DateTime']
    readonly dishBackgroundImage?: Maybe<DishBackgroundImages>
    readonly hasTimeslots: Scalars['Boolean']
    readonly id: Scalars['ID']
    readonly isOrderOnAccount: Scalars['Boolean']
    readonly isQrCodePickup?: Maybe<Scalars['Boolean']>
    readonly name: Scalars['String']
    readonly officeDays: ReadonlyArray<OfficeDay>
    readonly orderDeadlineMarginMinutes: Scalars['Int']
    readonly serviceReferenceID: Scalars['String']
    readonly type: RestaurantTypeEnum
    readonly updatedAt: Scalars['DateTime']
}

export enum RestaurantAmbianceImageVariantEnum {
    /** Original */
    Original = 'ORIGINAL',
    /** Rectangle 615 */
    Rectangle_615 = 'RECTANGLE_615',
}

export interface RestaurantBelongsTo {
    readonly connect: Scalars['ID']
}

export interface RestaurantQueryOptions {
    /** Defaults to LOCATION */
    readonly scope?: InputMaybe<RestaurantQueryScopeEnum>
}

export enum RestaurantQueryScopeEnum {
    /** Client */
    Client = 'CLIENT',
    /** Location */
    Location = 'LOCATION',
}

export enum RestaurantTypeEnum {
    /** Delivery */
    Delivery = 'DELIVERY',
    /** Order on demand */
    OrderOnDemand = 'ORDER_ON_DEMAND',
    /** Restaurant */
    Restaurant = 'RESTAURANT',
}

export interface RestaurantsInput {
    readonly first: Scalars['Int']
    readonly page?: InputMaybe<Scalars['Int']>
}

export interface RestaurantsPaginator {
    readonly data: ReadonlyArray<Restaurant>
    readonly paginatorInfo: PaginatorInfo
}

export interface Role {
    readonly createdAt: Scalars['DateTime']
    readonly id: Scalars['ID']
    readonly name: RoleNameEnum
    readonly updatedAt: Scalars['DateTime']
}

export enum RoleNameEnum {
    /** Admin */
    Admin = 'ADMIN',
    /** Curator */
    Curator = 'CURATOR',
    /** Employee */
    Employee = 'EMPLOYEE',
    /** Staff on location */
    StaffOnLocation = 'STAFF_ON_LOCATION',
    /** Web application */
    WebApplication = 'WEB_APPLICATION',
}

export interface ShortcodeRegisterInput {
    readonly email: Scalars['String']
    readonly firstName: Scalars['String']
    readonly lastName: Scalars['String']
    readonly password: Scalars['String']
    readonly password_confirmation: Scalars['String']
    readonly registrationShortcode: Scalars['String']
}

/** Information about pagination using a simple paginator. */
export interface SimplePaginatorInfo {
    /** Number of items in the current page. */
    readonly count: Scalars['Int']
    /** Index of the current page. */
    readonly currentPage: Scalars['Int']
    /** Index of the first item in the current page. */
    readonly firstItem?: Maybe<Scalars['Int']>
    /** Are there more pages after this one? */
    readonly hasMorePages: Scalars['Boolean']
    /** Index of the last item in the current page. */
    readonly lastItem?: Maybe<Scalars['Int']>
    /** Number of items per page. */
    readonly perPage: Scalars['Int']
}

/** Directions for ordering a list of records. */
export enum SortOrder {
    /** Sort records in ascending order. */
    Asc = 'ASC',
    /** Sort records in descending order. */
    Desc = 'DESC',
}

export enum StockMutationTypeEnum {
    /**
   *
   *     The CLAIM mutation increases a stock's amountClaimed by a given number, which will also increment its derived value
   *     amountReserved and decrement its derived value amountAvailable by the same number.
   *
   */
    Claim = 'CLAIM',
    /**
   *
   *     The DECREASE_TOTAL mutation decrements a stock's amountTotal by a given number, which will also decrements the
   *     derived values amountAvailable and amountIssuable by the same number.
   *
   *     ---
   *
   *     Note: The amount total can only be decreased so much that it will still satisfy the law amountTotal >= amountClaimed!
   *
   */
    DecreaseTotal = 'DECREASE_TOTAL',
    /**
   *
   *     The INCREASE_TOTAL mutation increments a stock's amountTotal by a given number, which will also increment the
   *     derived values amountAvailable and amountIssuable by the same number.
   *
   */
    IncreaseTotal = 'INCREASE_TOTAL',
    /**
   *
   *     The ISSUE mutation increases a stock's amountIssued by a given number, which will also _decrement_ its derived values
   *     amountReserved and amountIssuable by the same number.
   *
   */
    Issue = 'ISSUE',
    /**
   *
   *     The ISSUE_AVAILABLE mutation increases a stock's amountIssued AND amountClaimed by a given number, which will also
   *     decrement its derived values amountAvailable and amountIssuable by the same number.
   *
   *     Note: This is the same as first claiming and then issuing stock by the given number, used by kitchen screens to issue
   *     freely available stock in one go.
   *
   */
    IssueAvailable = 'ISSUE_AVAILABLE',
    /**
   *
   *     The UN_CLAIM mutation decrements a stock's amountClaimed by a given number, which will also decrement its derived
   *     value amountReserved and increment its derived value amountAvailable by the same number.
   *
   */
    UnClaim = 'UN_CLAIM',
    /**
   *
   *     The UN_CLAIM_ISSUED mutation decrements a stock's amountIssued AND amountClaimed by a given number, which will also
   *     increment its derived values amountAvailable and amountIssuable by the same number.
   *
   *     ---
   *
   *     Note: This is the same as first un-issuing and then un-claiming stock by the given number, which can be imagined as
   *     somebody who returns a previously issued dish, re-adding it to the available stock.
   *
   */
    UnClaimIssued = 'UN_CLAIM_ISSUED',
    /**
   *
   *     The UN_ISSUE mutation decreases a stock's amountIssued by a given number, which will also _increment_ its derived
   *     values amountReserved and amountIssuable by the same number.
   *
   */
    UnIssue = 'UN_ISSUE',
}

export interface StockNumbers {
    readonly amountAvailable: Scalars['Int']
    readonly amountClaimed: Scalars['Int']
    readonly amountIssuable: Scalars['Int']
    readonly amountIssued: Scalars['Int']
    readonly amountReserved: Scalars['Int']
    readonly amountTotal: Scalars['Int']
}

export interface StockUpdateResult {
    readonly id: Scalars['ID']
    /** The message ID may be null if the update was persisted but failed to be published to the Pubsub service */
    readonly messageID?: Maybe<Scalars['MessageID']>
    readonly mutationQuantity: Scalars['Int']
    readonly mutationType: StockMutationTypeEnum
    readonly nextNumbers: StockNumbers
    readonly prevNumbers: StockNumbers
}

export interface Tag {
    readonly id: Scalars['ID']
    readonly name: Scalars['String']
    readonly products: ReadonlyArray<Product>
}

export interface Timeslot {
    readonly capacityClaimed: Scalars['String']
    readonly capacityTotal: Scalars['String']
    readonly endsAt: Scalars['String']
    readonly id: Scalars['ID']
    readonly restaurant: Restaurant
    readonly startsAt: Scalars['String']
}

export interface TimeslotBelongsTo {
    readonly connect: Scalars['ID']
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
    /** Only return trashed results. */
    Only = 'ONLY',
    /** Return both trashed and non-trashed results. */
    With = 'WITH',
    /** Only return non-trashed results. */
    Without = 'WITHOUT',
}

export interface TwoFactorSecret {
    readonly decrypted: Scalars['String']
    readonly qr: Scalars['String']
}

export interface UpdatePassword {
    readonly old_password: Scalars['String']
    readonly password: Scalars['String']
    readonly password_confirmation: Scalars['String']
}

export interface UpdatePasswordResponse {
    readonly message?: Maybe<Scalars['String']>
    readonly status: Scalars['String']
}

export interface UpdateUserAllergensInput {
    readonly allergens?: InputMaybe<ReadonlyArray<AllergensBelongsTo>>
}

export interface UpdateUserInput {
    readonly cityName?: InputMaybe<Scalars['String']>
    readonly email?: InputMaybe<Scalars['String']>
    readonly firstName?: InputMaybe<Scalars['String']>
    readonly lastName?: InputMaybe<Scalars['String']>
    readonly location?: InputMaybe<LocationBelongsTo>
    readonly newsReadAt?: InputMaybe<Scalars['DateTime']>
    readonly postalCode?: InputMaybe<Scalars['String']>
    readonly streetName?: InputMaybe<Scalars['String']>
    readonly streetNumber?: InputMaybe<Scalars['String']>
}

export interface User {
    readonly cityName?: Maybe<Scalars['String']>
    readonly client: Client
    readonly createdAt: Scalars['DateTime']
    readonly email: Scalars['String']
    readonly firstName: Scalars['String']
    readonly hasConfirmedTwoFactor: Scalars['Boolean']
    readonly id: Scalars['ID']
    readonly lastName: Scalars['String']
    readonly location?: Maybe<Location>
    readonly newsReadAt?: Maybe<Scalars['DateTime']>
    readonly officeDays: ReadonlyArray<OfficeDay>
    readonly postalCode?: Maybe<Scalars['String']>
    readonly roles: ReadonlyArray<Role>
    readonly status: UserStatusEnum
    readonly streetName?: Maybe<Scalars['String']>
    readonly streetNumber?: Maybe<Scalars['String']>
    readonly updatedAt: Scalars['DateTime']
    readonly userAllergens: ReadonlyArray<UserAllergen>
}

export interface UserAllergen {
    readonly allergen: Allergen
    readonly createdAt: Scalars['DateTime']
    readonly id: Scalars['ID']
    readonly updatedAt: Scalars['DateTime']
    readonly user: User
}

export interface UserStatus {
    readonly key: UserStatusEnum
    readonly value: Scalars['String']
}

export enum UserStatusEnum {
    /** Active */
    Active = 'ACTIVE',
    /** Blocked */
    Blocked = 'BLOCKED',
    /** Must verify email */
    MustVerifyEmail = 'MUST_VERIFY_EMAIL',
    /** Pending */
    Pending = 'PENDING',
}

export interface Vat {
    readonly currentTariff: VatTariff
    readonly id: Scalars['ID']
    readonly name: Scalars['String']
    readonly tariffs: ReadonlyArray<VatTariff>
}

export interface VatTariff {
    readonly endsAt?: Maybe<Scalars['Date']>
    readonly id: Scalars['ID']
    readonly percent: Scalars['Int']
    readonly startsAt: Scalars['Date']
}

export interface VatTariffBelongsTo {
    readonly connect: Scalars['ID']
}

export interface VerifyEmailInput {
    readonly token: Scalars['String']
}

export enum WeekDayEnum {
    /** Friday */
    Friday = 'FRIDAY',
    /** Monday */
    Monday = 'MONDAY',
    /** Saturday */
    Saturday = 'SATURDAY',
    /** Sunday */
    Sunday = 'SUNDAY',
    /** Thursday */
    Thursday = 'THURSDAY',
    /** Tuesday */
    Tuesday = 'TUESDAY',
    /** Wednesday */
    Wednesday = 'WEDNESDAY',
}

export interface TimeslotsInput {
    readonly date: Scalars['Date']
    readonly restaurant: RestaurantBelongsTo
}

export type ApiVersionQueryVariables = Exact<{ [key: string]: never }>


export type ApiVersionQuery = { readonly apiVersion: { readonly version: string } }

export type LoginWithShortcodeMutationVariables = Exact<{
    input: LoginWithShortcodeInput
}>


export type LoginWithShortcodeMutation = { readonly loginWithShortcode: AuthPayloadFragment }

export type RefreshTokenMutationVariables = Exact<{
    input?: InputMaybe<RefreshTokenInput>
}>


export type RefreshTokenMutation = { readonly refreshToken: RefreshResponseFragment }

export type MeQueryVariables = Exact<{ [key: string]: never }>


export type MeQuery = { readonly me: MeFragment }

export type AuthPayloadFragment = { readonly __typename: 'AuthPayload'; readonly access_token?: string | null; readonly refresh_token?: string | null; readonly expires_in?: number | null; readonly token_type?: string | null; readonly user?: { readonly id: string } | null }

export type MeFragment = { readonly __typename: 'User'; readonly id: string; readonly createdAt: string; readonly updatedAt: string; readonly client: { readonly id: string; readonly registrationShortcode: string }; readonly location?: { readonly id: string; readonly shortcode?: string | null } | null }

export type RefreshResponseFragment = { readonly access_token: string; readonly expires_in: number; readonly refresh_token: string; readonly token_type: string }

export type LatestNewsPageIndexQueryVariables = Exact<{ [key: string]: never }>


export type LatestNewsPageIndexQuery = { readonly newsPages?: { readonly data: ReadonlyArray<NewsPageIndexFragment> } | null }

export type NewsPageQueryVariables = Exact<{
    id: Scalars['ID']
}>


export type NewsPageQuery = { readonly newsPage: NewsPageFragment }

export type NewsPageIndexFragment = { readonly __typename: 'NewsPage'; readonly id: string; readonly intro: string; readonly published_at?: string | null; readonly title: string; readonly heroImage?: string | null }

export type NewsPageFragment = (
  { readonly heroImage?: string | null; readonly tagline?: string | null; readonly articles: ReadonlyArray<{ readonly id: string; readonly title?: string | null; readonly body?: string | null; readonly image?: string | null; readonly position?: string | null }> }
  & NewsPageIndexFragment
)

export type ClientQueryVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>
}>


export type ClientQuery = { readonly client?: ClientFragment | null }

export type LocationQueryVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>
    shortcode?: InputMaybe<Scalars['String']>
}>


export type LocationQuery = { readonly location: LocationFragment }

export type ClientFragment = { readonly __typename: 'Client'; readonly id: string; readonly name: string; readonly logo: string; readonly registrationShortcode: string; readonly emailQuestionsTo: string; readonly themes: { readonly web: ClientThemeFragment }; readonly domains: ReadonlyArray<{ readonly __typename: 'Domain'; readonly id: string; readonly name: string }> }

export type LocationFragment = { readonly id: string; readonly name: string; readonly streetName: string; readonly streetNumber: string; readonly postalCode: string; readonly cityName: string; readonly costCenter?: string | null; readonly shortcode?: string | null; readonly restaurants: ReadonlyArray<RestaurantFragment> }

export type ClientThemeFragment = { readonly __typename: 'ClientTheme'; readonly colorBackgroundFirstStop: string; readonly colorBackgroundOverlay: string; readonly colorBackgroundOverlayContrast: string; readonly colorBackgroundSecondStop: string; readonly colorError: string; readonly colorErrorContrast: string; readonly colorPrimary: string; readonly colorPrimaryContrast: string; readonly colorSecondary: string; readonly colorSecondaryContrast: string; readonly colorSuccess: string; readonly colorSuccessContrast: string; readonly colorTertiary: string; readonly colorTertiaryContrast: string; readonly colorTextDark: string; readonly colorTextLight: string; readonly colorWarning: string; readonly colorWarningContrast: string }

export type PaginatorFragment = { readonly count: number; readonly currentPage: number; readonly firstItem?: number | null; readonly hasMorePages: boolean; readonly lastItem?: number | null; readonly lastPage: number; readonly perPage: number; readonly total: number }

export type MenuItemsQueryVariables = Exact<{
    input: MenuItemsInput
}>


export type MenuItemsQuery = { readonly menuItems: ReadonlyArray<MenuItemFragment> }

export type MenuItemQueryVariables = Exact<{
    id: Scalars['ID']
}>


export type MenuItemQuery = { readonly menuItem: MenuItemFragment }

export type MenuItemStockQueryVariables = Exact<{
    id: Scalars['ID']
}>


export type MenuItemStockQuery = { readonly menuItemStock?: MenuItemStockFragment | null }

export type MenuItemFragment = { readonly id: string; readonly sellingPriceInCents: number; readonly restaurant: { readonly id: string }; readonly product: ProductFragment; readonly menuItemStocks: ReadonlyArray<MenuItemStockFragment> }

export type MenuItemStockFragment = { readonly id: string; readonly date: string; readonly stockTotal: number; readonly stockClaimed: number; readonly menuItem: { readonly id: string } }

export type ProductFragment = { readonly id: string; readonly name: string; readonly tagline?: string | null; readonly type: ProductTypeEnum; readonly tags: ReadonlyArray<TagFragment>; readonly category: { readonly name: string }; readonly currentVersion: ProductVersionFragment }

export type ProductVersionFragment = { readonly id: string; readonly description?: string | null; readonly additions: ReadonlyArray<AdditionFragment>; readonly allergens: ReadonlyArray<AllergenFragment>; readonly nutrients: ReadonlyArray<NutrientFragment>; readonly vat: { readonly currentTariff: { readonly id: string; readonly percent: number } }; readonly image?: { readonly variants: { readonly SQUARE_150: string; readonly SQUARE_300: string; readonly SQUARE_600: string; readonly ORIGINAL: string } } | null }

export type AdditionFragment = { readonly id: string; readonly name: string; readonly priceInCents: number; readonly isSelected: boolean }

export type AllergenFragment = { readonly id: string; readonly name: string; readonly icon?: string | null }

export type NutrientFragment = { readonly __typename: 'Nutrient'; readonly perHundredGram?: number | null; readonly perPortion?: number | null; readonly referenceIntake?: number | null; readonly NutrientType?: { readonly __typename: 'NutrientType'; readonly id: string; readonly name: string; readonly unit: NutrientTypeUnitEnum } | null }

export type TagFragment = { readonly __typename: 'Tag'; readonly id: string; readonly name: string }

export type RestaurantsQueryVariables = Exact<{
    input: RestaurantsInput
}>


export type RestaurantsQuery = { readonly restaurants: { readonly data: ReadonlyArray<RestaurantFragment>; readonly paginatorInfo: PaginatorFragment } }

export type RestaurantQueryVariables = Exact<{
    id: Scalars['ID']
}>


export type RestaurantQuery = { readonly restaurant: RestaurantFragment }

export type RestaurantFragment = { readonly id: string; readonly name: string; readonly type: RestaurantTypeEnum; readonly hasTimeslots: boolean; readonly isQrCodePickup?: boolean | null; readonly isOrderOnAccount: boolean; readonly orderDeadlineMarginMinutes: number; readonly ambianceImage: { readonly original: string; readonly rectangle_615: string }; readonly officeDays: ReadonlyArray<OfficeDayFragment>; readonly dishBackgroundImage?: { readonly ORIGINAL: string; readonly SQUARE_150: string; readonly SQUARE_300: string; readonly SQUARE_600: string } | null }

export type OfficeDayFragment = { readonly dayOfTheWeek: WeekDayEnum; readonly openingTime?: string | null; readonly closingTime?: string | null }

export const AuthPayloadFragmentDoc = gql`
    fragment AuthPayload on AuthPayload {
  access_token
  refresh_token
  expires_in
  token_type
  __typename
  user {
    id
  }
}
    `
export const MeFragmentDoc = gql`
    fragment Me on User {
  __typename
  id
  createdAt
  updatedAt
  client {
    id
    registrationShortcode
  }
  location {
    id
    shortcode
  }
}
    `
export const RefreshResponseFragmentDoc = gql`
    fragment RefreshResponse on RefreshTokenPayload {
  access_token
  expires_in
  refresh_token
  token_type
}
    `
export const NewsPageIndexFragmentDoc = gql`
    fragment NewsPageIndex on NewsPage {
  id
  intro
  published_at
  title
  heroImage
  __typename
}
    `
export const NewsPageFragmentDoc = gql`
    fragment NewsPage on NewsPage {
  ...NewsPageIndex
  heroImage
  tagline
  articles {
    id
    title
    body
    image
    position
  }
}
    ${NewsPageIndexFragmentDoc}`
export const ClientThemeFragmentDoc = gql`
    fragment ClientTheme on ClientTheme {
  __typename
  colorBackgroundFirstStop
  colorBackgroundOverlay
  colorBackgroundOverlayContrast
  colorBackgroundSecondStop
  colorError
  colorErrorContrast
  colorPrimary
  colorPrimaryContrast
  colorSecondary
  colorSecondaryContrast
  colorSuccess
  colorSuccessContrast
  colorTertiary
  colorTertiaryContrast
  colorTextDark
  colorTextLight
  colorWarning
  colorWarningContrast
}
    `
export const ClientFragmentDoc = gql`
    fragment Client on Client {
  __typename
  id
  name
  logo
  registrationShortcode
  emailQuestionsTo
  themes {
    web {
      ...ClientTheme
    }
  }
  domains {
    id
    name
    __typename
  }
}
    ${ClientThemeFragmentDoc}`
export const OfficeDayFragmentDoc = gql`
    fragment OfficeDay on OfficeDay {
  dayOfTheWeek
  openingTime
  closingTime
}
    `
export const RestaurantFragmentDoc = gql`
    fragment Restaurant on Restaurant {
  id
  name
  type
  hasTimeslots
  isQrCodePickup
  isOrderOnAccount
  orderDeadlineMarginMinutes
  ambianceImage {
    original
    rectangle_615
  }
  officeDays {
    ...OfficeDay
  }
  dishBackgroundImage {
    ORIGINAL
    SQUARE_150
    SQUARE_300
    SQUARE_600
  }
}
    ${OfficeDayFragmentDoc}`
export const LocationFragmentDoc = gql`
    fragment Location on Location {
  id
  name
  streetName
  streetNumber
  postalCode
  cityName
  costCenter
  shortcode
  restaurants {
    ...Restaurant
  }
}
    ${RestaurantFragmentDoc}`
export const PaginatorFragmentDoc = gql`
    fragment Paginator on PaginatorInfo {
  count
  currentPage
  firstItem
  hasMorePages
  lastItem
  lastPage
  perPage
  total
}
    `
export const TagFragmentDoc = gql`
    fragment Tag on Tag {
  id
  name
  __typename
}
    `
export const AdditionFragmentDoc = gql`
    fragment Addition on Addition {
  id
  name
  priceInCents
  isSelected
}
    `
export const AllergenFragmentDoc = gql`
    fragment Allergen on Allergen {
  id
  name
  icon
}
    `
export const NutrientFragmentDoc = gql`
    fragment Nutrient on Nutrient {
  __typename
  NutrientType {
    __typename
    id
    name
    unit
  }
  perHundredGram
  perPortion
  referenceIntake
}
    `
export const ProductVersionFragmentDoc = gql`
    fragment ProductVersion on ProductVersion {
  id
  additions {
    ...Addition
  }
  allergens {
    ...Allergen
  }
  nutrients {
    ...Nutrient
  }
  description
  vat {
    currentTariff {
      id
      percent
    }
  }
  image {
    variants {
      SQUARE_150
      SQUARE_300
      SQUARE_600
      ORIGINAL
    }
  }
}
    ${AdditionFragmentDoc}
${AllergenFragmentDoc}
${NutrientFragmentDoc}`
export const ProductFragmentDoc = gql`
    fragment Product on Product {
  id
  name
  tagline
  tags {
    ...Tag
  }
  type
  category {
    name
  }
  currentVersion {
    ...ProductVersion
  }
}
    ${TagFragmentDoc}
${ProductVersionFragmentDoc}`
export const MenuItemStockFragmentDoc = gql`
    fragment MenuItemStock on MenuItemStock {
  id
  date
  stockTotal
  stockClaimed
  menuItem {
    id
  }
}
    `
export const MenuItemFragmentDoc = gql`
    fragment MenuItem on MenuItem {
  id
  sellingPriceInCents
  restaurant {
    id
  }
  product {
    ...Product
  }
  menuItemStocks {
    ...MenuItemStock
  }
}
    ${ProductFragmentDoc}
${MenuItemStockFragmentDoc}`
export const ApiVersionDocument = gql`
    query apiVersion {
  apiVersion {
    version
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class ApiVersionQueryService extends Apollo.Query<ApiVersionQuery, ApiVersionQueryVariables> {
    override document = ApiVersionDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const LoginWithShortcodeDocument = gql`
    mutation loginWithShortcode($input: LoginWithShortcodeInput!) {
  loginWithShortcode(input: $input) {
    ...AuthPayload
  }
}
    ${AuthPayloadFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class LoginWithShortcodeMutationService extends Apollo.Mutation<LoginWithShortcodeMutation, LoginWithShortcodeMutationVariables> {
    override document = LoginWithShortcodeDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RefreshTokenDocument = gql`
    mutation refreshToken($input: RefreshTokenInput) {
  refreshToken(input: $input) {
    ...RefreshResponse
  }
}
    ${RefreshResponseFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class RefreshTokenMutationService extends Apollo.Mutation<RefreshTokenMutation, RefreshTokenMutationVariables> {
    override document = RefreshTokenDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const MeDocument = gql`
    query me {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class MeQueryService extends Apollo.Query<MeQuery, MeQueryVariables> {
    override document = MeDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const LatestNewsPageIndexDocument = gql`
    query latestNewsPageIndex {
  newsPages(input: {first: 1, page: 1}) {
    data {
      ...NewsPageIndex
    }
  }
}
    ${NewsPageIndexFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class LatestNewsPageIndexQueryService extends Apollo.Query<LatestNewsPageIndexQuery, LatestNewsPageIndexQueryVariables> {
    override document = LatestNewsPageIndexDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const NewsPageDocument = gql`
    query newsPage($id: ID!) {
  newsPage(id: $id) {
    ...NewsPage
  }
}
    ${NewsPageFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class NewsPageQueryService extends Apollo.Query<NewsPageQuery, NewsPageQueryVariables> {
    override document = NewsPageDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ClientDocument = gql`
    query client($id: ID) {
  client(id: $id) {
    ...Client
  }
}
    ${ClientFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class ClientQueryService extends Apollo.Query<ClientQuery, ClientQueryVariables> {
    override document = ClientDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const LocationDocument = gql`
    query location($id: ID, $shortcode: String) {
  location(id: $id, shortcode: $shortcode) {
    ...Location
  }
}
    ${LocationFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class LocationQueryService extends Apollo.Query<LocationQuery, LocationQueryVariables> {
    override document = LocationDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const MenuItemsDocument = gql`
    query menuItems($input: MenuItemsInput!) {
  menuItems(input: $input) {
    ...MenuItem
  }
}
    ${MenuItemFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class MenuItemsQueryService extends Apollo.Query<MenuItemsQuery, MenuItemsQueryVariables> {
    override document = MenuItemsDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const MenuItemDocument = gql`
    query menuItem($id: ID!) {
  menuItem(id: $id) {
    ...MenuItem
  }
}
    ${MenuItemFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class MenuItemQueryService extends Apollo.Query<MenuItemQuery, MenuItemQueryVariables> {
    override document = MenuItemDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const MenuItemStockDocument = gql`
    query menuItemStock($id: ID!) {
  menuItemStock(id: $id) {
    ...MenuItemStock
  }
}
    ${MenuItemStockFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class MenuItemStockQueryService extends Apollo.Query<MenuItemStockQuery, MenuItemStockQueryVariables> {
    override document = MenuItemStockDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RestaurantsDocument = gql`
    query restaurants($input: RestaurantsInput!) {
  restaurants(input: $input) {
    data {
      ...Restaurant
    }
    paginatorInfo {
      ...Paginator
    }
  }
}
    ${RestaurantFragmentDoc}
${PaginatorFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class RestaurantsQueryService extends Apollo.Query<RestaurantsQuery, RestaurantsQueryVariables> {
    override document = RestaurantsDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RestaurantDocument = gql`
    query restaurant($id: ID!) {
  restaurant(id: $id) {
    ...Restaurant
  }
}
    ${RestaurantFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class RestaurantQueryService extends Apollo.Query<RestaurantQuery, RestaurantQueryVariables> {
    override document = RestaurantDocument
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
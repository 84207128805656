import { Environment, EnvironmentVariables } from '@app-types/environment.types'

export const environment: EnvironmentVariables = {
    productionMode: true,
    name: Environment.Production,

    api: {
        clientId: '3',
        clientSecret: '2o3sF2uoTdp2fDDV1ol3jSV54fmngtZKNBrRf8Tg',
        schema: 'https',
        hostname: 'api.joinprogram.com',
        acceptVersionRange: '>=2.17.0 <3.0.0',
    },

    pubsub: {
        schema: 'wss',
        hostname: 'v2.pubsub.joinprogram.com',
    },
}

import { formatDate as formatDateVendor } from '@angular/common'
import { WeekDayEnum } from '@app-graphql/api-schema'
import { isString } from '@app-lib/common.lib'
import { comparator, keys } from 'ramda'
import { Observable, timer } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'

// ------------------------------------------------------------------------------
//      Signal types
// ------------------------------------------------------------------------------

/**
 * Signal type for any date representation (where time is irrelevant)
 */
export type AnyDate = string | Date

/**
 * Signal type for any date-time representation.
 */
export type AnyDateTime = string | Date

/**
 * Signal type for any date or date-time representation
 */
export type AnyDateOrDateTime = AnyDate | AnyDateTime

// ------------------------------------------------------------------------------
//      Weekday mapping
// ------------------------------------------------------------------------------

/**
 * ISO day numbers union, with 0 representing sunday, and 1 - 6 representing
 * monday - saturday.
 * This aligns with values yielded by {@link Date.prototype.getDay `Date.getDay()`}.
 */
export type ISODayNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6

/**
 * A 'bundle' interface that pairs a {@link WeekDayEnum} value with an {@link ISODayNumber ISO day number} value.
 */
export interface ISOWeekDay {
    weekDay: WeekDayEnum
    isoDayNumber: ISODayNumber
}

const isoDayNumbersMap: { [K in WeekDayEnum]: ISODayNumber } = {
    [WeekDayEnum.Monday]: 1,
    [WeekDayEnum.Tuesday]: 2,
    [WeekDayEnum.Wednesday]: 3,
    [WeekDayEnum.Thursday]: 4,
    [WeekDayEnum.Friday]: 5,
    [WeekDayEnum.Saturday]: 6,
    [WeekDayEnum.Sunday]: 0,
}

/**
 * Get the corresponding {@link ISODayNumber ISO day number} value for a given {@link WeekDayEnum}.
 */
export function isoDayNumber(dayOfTheWeek: WeekDayEnum): ISODayNumber {
    return isoDayNumbersMap[dayOfTheWeek]
}

/**
 * Get the corresponding {@link WeekDayEnum Week day enum} value for a given {@link ISODayNumber}.
 */
export function getDayFromDayNumber(dayNumber: ISODayNumber): WeekDayEnum {
    const weekDay = keys(isoDayNumbersMap).find((key) => isoDayNumbersMap[key] === dayNumber)
    if (! weekDay) throw new Error('The day number provided is not valid')
    return weekDay
}
/**
 * Returns a {@link comparator} function that takes 2 {@link WeekDayEnum weekday enum} values and compares them
 * for sorting.
 */
export function weekdayComparator(weekStartsOnMonday: boolean = true): (x: WeekDayEnum, y: WeekDayEnum) => number {
    const transformer = (x: WeekDayEnum) => {
        return x === WeekDayEnum.Sunday && weekStartsOnMonday
            ? 7
            : isoDayNumber(x)
    }

    return comparator((x, y) => transformer(x) < transformer(y))
}

export function todaysWeekday(): ISOWeekDay {
    const dayNumber: ISODayNumber = now().getDay() as ISODayNumber
    const weekDayEnum: WeekDayEnum = getDayFromDayNumber(dayNumber)
    return {
        weekDay: weekDayEnum,
        isoDayNumber: dayNumber,
    }
}

// ------------------------------------------------------------------------------
//      Date shorthands
// ------------------------------------------------------------------------------

export function now(): Date {
    return new Date()
}

// ------------------------------------------------------------------------------
//      Date/time formatting
// ------------------------------------------------------------------------------

/**
 * Formats the given date (or today by default) in the default system date format: `'yyyy-MM-dd'`.
 */
export function formatDate(date: Date = now(), locale: string = 'en-US'): string {
    return formatDateVendor(date, 'yyyy-MM-dd', locale)
}

/**
 * Formats the given date-time (or now by default) in the default system date-time format: `'yyyy-MM-dd HH:mm:ss'`.
 */
export function formatDateTime(date: Date = now(), locale: string = 'en-US'): string {
    return formatDateVendor(date, 'yyyy-MM-dd HH:mm:ss', locale)
}

// ------------------------------------------------------------------------------
//      Date/time parsing
// ------------------------------------------------------------------------------

/**
 * Attempts to parse the given date-time string – `'yyyy-MM-dd HH:mm:ss'` – and returns a Date instance on success.
 * If given the flag `strict: true`, an error will be thrown upon parsing failure. If `strict` is omitted or _falsy_
 * `undefined` is returned upon parsing failure.
 *
 * ---
 *
 * This custom function is required for
 * {@link https://stackoverflow.com/questions/58506432/invalid-date-ionic-3-in-ios cross-platform compatibility}
 */
export function parseDateTime(dateTimeString: string, strict: true): Date
/**
 * Attempts to parse the given date-time string – `'yyyy-MM-dd HH:mm:ss'` – and returns a Date instance on success.
 * If given the flag `strict: true`, an error will be thrown upon parsing failure. If `strict` is omitted or _falsy_
 * `undefined` is returned upon parsing failure.
 *
 * ---
 *
 * This custom function is required for
 * {@link https://stackoverflow.com/questions/58506432/invalid-date-ionic-3-in-ios cross-platform compatibility}
 */
export function parseDateTime(dateTimeString: string, strict?: false): Date | undefined
/**
 * Attempts to parse the given date-time string – `'yyyy-MM-dd HH:mm:ss'` – and returns a Date instance on success.
 * If given the flag `strict: true`, an error will be thrown upon parsing failure. If `strict` is omitted or _falsy_
 * `undefined` is returned upon parsing failure.
 *
 * ---
 *
 * This custom function is required for
 * {@link https://stackoverflow.com/questions/58506432/invalid-date-ionic-3-in-ios cross-platform compatibility}
 */
export function parseDateTime(dateTimeString: string, strict?: boolean): Date | undefined
export function parseDateTime(dateTimeString: string, strict = false): Date | undefined {
    try {
        const matchResult = dateTimeString.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}:\d{2}:\d{2})$/)

        if (matchResult !== null) {
            const [, yyyy, mm, dd, time] = matchResult
            return new Date(`${mm}/${dd}/${yyyy} ${time}`)
        }

        if (strict) {
            throw new Error('Expected RegExpMatchArray but got null.')
        }

        return undefined
    } catch (error: any) {
        if (strict) {
            throw new Error(`Failed to parse date-time string '${dateTimeString}'. ${error?.message ?? String(error)}`)
        }

        return undefined
    }
}

/**
 * Attempts to parse the given date string – `'yyyy-MM-dd'` – and returns a Date instance on success.
 * If given the flag `strict: true`, an error will be thrown upon parsing failure. If `strict` is omitted or _falsy_
 * `undefined` is returned upon parsing failure.
 *
 * ---
 *
 * This custom function is required for
 * {@link https://stackoverflow.com/questions/58506432/invalid-date-ionic-3-in-ios cross-platform compatibility}
 */
export function parseDate(dateString: string, strict: true): Date
/**
 * Attempts to parse the given date string – `'yyyy-MM-dd'` – and returns a Date instance on success.
 * If given the flag `strict: true`, an error will be thrown upon parsing failure. If `strict` is omitted or _falsy_
 * `undefined` is returned upon parsing failure.
 *
 * ---
 *
 * This custom function is required for
 * {@link https://stackoverflow.com/questions/58506432/invalid-date-ionic-3-in-ios cross-platform compatibility}
 */
export function parseDate(dateString: string, strict?: false): Date | undefined
/**
 * Attempts to parse the given date string – `'yyyy-MM-dd'` – and returns a Date instance on success.
 * If given the flag `strict: true`, an error will be thrown upon parsing failure. If `strict` is omitted or _falsy_
 * `undefined` is returned upon parsing failure.
 *
 * ---
 *
 * This custom function is required for
 * {@link https://stackoverflow.com/questions/58506432/invalid-date-ionic-3-in-ios cross-platform compatibility}
 */
export function parseDate(dateString: string, strict?: boolean): Date | undefined
export function parseDate(dateString: string, strict = false): Date | undefined {
    try {
        const matchResult = dateString.match(/^(\d{4})-(\d{2})-(\d{2})$/)

        if (matchResult !== null) {
            const [, yyyy, mm, dd] = matchResult
            return new Date(`${mm}/${dd}/${yyyy}`)
        }

        if (strict) {
            throw new Error('Expected RegExpMatchArray but got null.')
        }

        return undefined
    } catch (error: any) {
        if (strict) {
            throw new Error(`Failed to parse date string '${dateString}'. ${error?.message ?? String(error)}`)
        }

        return undefined
    }
}

export const parseDateTimeStrict = (dateTimeString: string): Date => parseDateTime(dateTimeString, true)

// ------------------------------------------------------------------------------
//      Date time normalisation
// ------------------------------------------------------------------------------

export function ensureSerialised(date: AnyDateOrDateTime, type: 'date' | 'datetime', locale?: string): string {
    if (isString(date)) {
        return date
    }

    switch (type) {
        case 'date':
            return formatDate(date, locale)
        case 'datetime':
            return formatDateTime(date, locale)
    }
}

export function ensureParsed(date: AnyDateOrDateTime, type: 'date' | 'datetime', strict: true): Date
export function ensureParsed(date: AnyDateOrDateTime, type: 'date' | 'datetime', strict?: false): Date | undefined
export function ensureParsed(date: AnyDateOrDateTime, type: 'date' | 'datetime', strict = false): Date | undefined {
    if (date instanceof Date) {
        return date
    }

    switch (type) {
        case 'date':
            return parseDate(date, strict)
        case 'datetime':
            return parseDateTime(date, strict)
    }
}

// ------------------------------------------------------------------------------
//      Misc.
// ------------------------------------------------------------------------------

export enum DailyPeriod {
    Morning = 'morning',
    Afternoon = 'afternoon',
    Evening = 'evening',
    Night = 'night',
}

export type DailyPeriodObservable = Observable<DailyPeriod> & typeof DailyPeriod

/**
 * Creates an observable that emits the current {@link DailyPeriod daily period}.
 */
export function trackDailyPeriod(refreshIntervalSeconds: number = 60): DailyPeriodObservable {
    const obs$ = timer(0, refreshIntervalSeconds * 1000).pipe(
        map(getCurrentDailyPeriod),
        distinctUntilChanged(),
    )

    return Object.assign(obs$, {
        Morning: DailyPeriod.Morning as const,
        Afternoon: DailyPeriod.Afternoon as const,
        Evening: DailyPeriod.Evening as const,
        Night: DailyPeriod.Night as const,
    })
}

export function getCurrentDailyPeriod(): DailyPeriod {
    const hours = now().getHours()
    if (hours < 5) return DailyPeriod.Night
    if (hours < 12) return DailyPeriod.Morning
    if (hours < 18) return DailyPeriod.Afternoon
    return DailyPeriod.Evening
}
